import React from 'react';

import SEBLogo from '@assets/images/SEB-logo.png';

export const PartnerSEB = () => (
  <div className="screening-test__partner screening-test__partner--SEB">
    <img src={SEBLogo} alt="SEB" />
    <h3 className="margin-bottom--xs">Ring SEB vårdplaneringen för inbjudan till Joint Academy</h3>
    <a className="screening-test__phone-link" href="tel:0201111633">020-11 11 633</a>
    <p className="margin-top--xs">Öppet måndag–fredag kl. 8–16:30</p>
  </div>
);
