import React from 'react';
import { useTranslation } from 'react-i18next';
import { TMarkets } from '@/src/types/common';
import SmsTag from '@components/sms/SmsTag';
import { getToken } from '@/src/common/helpers/token';
import { isSSR } from '@/src/common/helpers/ssr';
import { trackAndGoTo } from '@/src/common/helpers/tracking';
import { getPartner, partnerExists } from '@/src/common/helpers/partner';
import { PartnerSEB } from '@components/screeningTest/views/result/PartnerSEB';
import { PartnerEA } from '@components/screeningTest/views/result/PartnerEA';

type TPageOther = {
  market: TMarkets,
  buttonDownloadUrl?: string,
};

const PageOther = ({ market, buttonDownloadUrl }: TPageOther) => {
  const { t } = useTranslation();
  const isPartnerSEB = getPartner() === 'SEB';
  const isPartnerEA = getPartner() === 'EA';

  return (
    <section data-theme="white">
      <div className="container container--sm margin-bottom--md" style={{ maxWidth: 650 }}>
        <div className="text--component margin-top--sm text--center">
          <h2 className="margin-bottom--sm">{t('page_other.title')}</h2>

          <p>
            {t('page_other.text')}
          </p>
        </div>
      </div>

      <div className="container container--sm margin-bottom--lg">
        {isPartnerSEB && <PartnerSEB />}

        {isPartnerEA && <PartnerEA />}

        {!partnerExists() && (
          <>
            <div className="hide--md text--center">
              <a
                className="btn btn--lg btn--primary mobile_download"
                href="#"
                onClick={() => trackAndGoTo(buttonDownloadUrl || '#', 'download')}
              >
                {t('result.download_app')}
              </a>
            </div>
            <div className="display--md">
              <SmsTag
                tagHeadline={t('result.sms_tagline')}
                dataToken={isSSR() ? '' : getToken(market)}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default PageOther;
