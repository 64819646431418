import React from 'react';

import EALogo from '@assets/images/EuroAccident.svg';

export const PartnerEA = () => (
  <div className="screening-test__partner screening-test__partner--EA">
    <img src={EALogo} alt="Euro Accident" />
    <h3 className="margin-bottom--xs">Ring Euro Accident vårdplaneringen för inbjudan till Joint Academy</h3>
    <a className="screening-test__phone-link" href="tel:0771105010">077-110 50 10</a>
    <p className="margin-top--xs">Öppet måndag–fredag kl. 8–16:30</p>
  </div>
);
